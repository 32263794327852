<template>
  <div v-if="item"
       :class="[{'open-item' : item.child}, {'active-item' : active}, {'parent-active-item' : childActive}]"
       class="vsm-item mobile-item">
    <template v-if="!item.child">

      <router-link v-if="isRouterLink"
                   :disabled="item.disabled"
                   :to="item.href"
                   class="vsm-link">
        <base-material-design-icon v-if="item.icon"
                                   :icon="item.icon"
                                   class="vsm-icon"/>
        <span style="color: white!important;">{{ item.title }}</span>
      </router-link>
      <a v-else
         :disabled="item.disabled"
         :href="item.href"
         class="vsm-link">
        <base-material-design-icon v-if="item.icon"
                                   :icon="item.icon"
                                   class="vsm-icon"/>
        {{ item.title }}
      </a>
    </template>
    <template v-else>
      <div class="vsm-link">
        <base-material-design-icon v-if="item.icon"
                                   :icon="item.icon"
                                   class="vsm-icon"/>
        {{ item.title }}
        <i class="vsm-arrow open-arrow"></i>
      </div>
    </template>

  </div>
</template>

<script>
  import BaseMaterialDesignIcon from '@/components/baseComponents/baseIcons/BaseMaterialDesignIcon';
  import SubItem from './SubItem';
  import { itemMixin } from '../mixin';

  export default {
    props: { item: { type: Object } },
    components: { BaseMaterialDesignIcon, SubItem },
    mixins: [itemMixin],
    watch: {
      item() {
        this.active = this.item && this.item.href ? this.isLinkActive(this.item) : false;
        this.childActive = this.item && this.item.child
          ? this.isChildActive(this.item.child)
          : false;
      },
    },
  };
</script>
