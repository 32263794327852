import Vue from 'vue';
import { usersMePrivileges as urlLayer } from '@/api/url_layer';

export const usersMePrivileges = {
  namespaced: true,
  state: { privileges: {} },
  mutations: {
    setUsersMePrivilege(state, { privilegeId, privilege }) {
      state.privileges[privilegeId] = privilege;
    },
    setUsersMePrivilegeState(state, { privilegeId, privilegeState }) {
      state.privileges[privilegeId].states.name = privilegeState;
    },
  },
  actions: {
    async loadUsersMePrivilege({ dispatch, commit }, privilegeId) {
      try {
        const { data } = await urlLayer.loadUsersMePrivilege(privilegeId);
        commit('setUsersMePrivilege', { privilegeId, privilege: data });
      } catch (error) {
        dispatch('notifyHandler', { title: error, type: 'error', duration: 7000 });
      }
    },
    async updateUsersMePrivilegeState({ commit, dispatch }, { privilegeId, privilegeState }) {
      let notification = {};
      try {
        const { data } = await urlLayer.updateUsersMePrivilegeState(privilegeId, privilegeState);
        commit('setUsersMePrivilegeState', { privilegeId, privilegeState });
        notification = { title: data.msg, type: 'success' };
      } catch (error) {
        notification = { title: error.response.data.error, type: 'error', duration: 7000 };
      }
      dispatch('notifyHandler', notification);
    },
    // eslint-disable-next-line no-empty-pattern
    notifyHandler({}, notification) {
      Vue.notify(notification);
    },
  },
  getters: { privilegeById: (state) => (privilegeId) => state.privileges[privilegeId] || {} },
};
