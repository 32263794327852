import { reviewers as urlLayer } from '@/api/url_layer';
import { capitalizeText } from '@/helpers';

export const reviewers = {
  namespaced: true,
  state: {
    reviewers: {
      synchrotron: [],
      cryoem: [],
    },
  },
  mutations: {
    setReviewers(state, { newReviewers, environment }) {
      state.reviewers[environment] = newReviewers;
    },
  },
  actions: {
    async loadReviewers({ commit }, environment) {
      const response = await urlLayer[`loadReviewers${capitalizeText(environment)}`]();
      commit('setReviewers', { newReviewers: response.data, environment });
    },
  },
  getters: {
    reviewerById: (state) => (reviewerId, environment) => state.reviewers[environment].find(
      (reviewer) => reviewerId === reviewer._id.$oid,
    ),
  },
};
