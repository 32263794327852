<template>
  <i class="material-icons"
     v-text="icon"/>
</template>

<script>
  export default {
    name: 'BaseMaterialDesignIcon',
    props: {
      icon: {
        type: String,
        required: true,
      },
    },
  };
</script>

<style scoped>

</style>
