import { proposalForm as urlLayer } from '@/api/url_layer';
import Vue from 'vue';
import { isProposalOfType } from '@/helpers/proposalForm';

export const proposalForm = {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async submitProposal({ dispatch }, { objectId, proposal }) {
      let notification = {};
      try {
        let response = {};
        if (!isProposalOfType(proposal.general, 'Rapid access')) {
          response = await urlLayer.submitProposal(objectId, proposal);
        } else {
          response = await urlLayer.submitRapidAccessProposal(objectId, proposal);
        }
        if (response.status === 200) {
          notification = { title: 'Submitted successfully!', type: 'success' };
        } else {
          notification = {
            title: 'Could not submit! Please try again in a moment or contact User Office',
            type: 'error',
          };
        }
      } catch {
        notification = {
          title: 'Could not submit! Please try again in a moment or contact User Office',
          type: 'error',
        };
      }
      dispatch('notifyHandler', notification);
      return notification.type === 'success';
    },
    notifyHandler({}, notification) {
      Vue.notify(notification);
    },
  },
  getters: {},
};
