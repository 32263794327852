import axios from 'axios';
import Vue from 'vue';
import _ from 'lodash';
import { users as url_layer } from '@/api/url_layer';
import { hasProperty } from '@/helpers';

export const login = {
  namespaced: true,
  state: {
    user: {},
    authorized: false,
    restricted: false,
    proposals_query: {},
  },
  mutations: {
    auth_user(state, userData) {
      state.authorized = userData.auth;
      state.user = userData.user;
    },
    clear_auth_user(state) {
      state.user = null;
      state.authorized = false;
    },
    // Removes navbar and side menu from view in certain pages(i.e. login) when user is logged in
    restrict(state, flag) {
      state.restricted = flag;
    },
    proposals_query(state, query) {
      state.proposals_query = query;
    },
    set_given_email(state, email) {
      state.user = { email };
    },
    set_self_affiliations(state, affiliations) {
      state.user.affiliations = affiliations;
    },
    setSelfConsents(state, consents) {
      state.user.consents = consents;
    },
  },
  actions: {
    login({ commit }, authData) {
      return new Promise((resolve, reject) => {
        commit('set_given_email', authData.email);
        axios.post('/login', {
          email: authData.email,
          password: authData.password,
        })
          .then((resp) => {
            if (!Object.keys(resp.data)
              .includes('error')) {
              commit('auth_user', {
                user: resp.data.user,
                auth: true,
              });
            } else {
              reject(resp.data);
            }
            if (resp.data.user !== undefined) {
              localStorage.setItem('user', JSON.stringify({ permissions: resp.data.user.permissions }));
            } else {
              localStorage.removeItem('user');
            }
            resolve();
          })
          .catch((error) => {
            localStorage.removeItem('user');
            console.log(error);
            reject(error);
          });
      });
    },
    auto_login({ dispatch }) {
      const user = JSON.parse(localStorage.getItem('user'));
      if (!user) {
        return null;
      }
      return dispatch('refresh_user');
    },
    user_update({ commit }, user) {
      return axios.post('/users/me', user)
        .then((response) => {
          if (response.data) {
            commit('auth_user', {
              user,
              auth: true,
            });
            Vue.notify({
              type: 'success',
              title: 'Saved successfully!',
            });
          }
          return Promise.resolve(response);
        }, undefined);
    },
    logout({ dispatch }) {
      return axios.get('/logout')
        .finally(() => {
          dispatch('auto_logout');
        });
    },
    auto_logout({ commit, dispatch }) {
      commit('clear_auth_user');
      dispatch('clear_storage');
    },
    clear_storage() {
      localStorage.removeItem('user');
    },
    async refresh_user({ commit }) {
      await axios.get('/users/me')
        .then((response) => {
          if (response.data) {
            localStorage.setItem(
              'user',
              JSON.stringify({ permissions: response.data.permissions }),
            );
            commit('auth_user', {
              user: response.data,
              auth: true,
            });
          }
          return Promise.resolve(response);
        })
        .catch((error) => Promise.reject(error));
    },
    load_self_affiliations({ commit }) {
      return url_layer.load_self_affiliations()
        .then((response) => {
          commit('set_self_affiliations', response.data);
          return Promise.resolve(response);
        });
    },
    async loadSelfConsents({ commit, dispatch }) {
      const response = await url_layer.loadSelfConsents();
      try {
        const userConsents = response.data.consent_data;
        commit('setSelfConsents', userConsents);
      } catch {
        dispatch('notifyHandler', { title: 'Cannot load user consents', type: 'error' });
      }
    },
    async updateSelfConsents({ dispatch }, consents) {
      const response = await url_layer.updateSelfConsents(consents);
      let notification = {};
      try {
        const isSubmitSuccessful = response.data;
        if (isSubmitSuccessful) {
          notification = { type: 'success', title: 'Consents changed successfully' };
          await dispatch('refreshLoggedUser');
        }
      } catch {
        notification = { type: 'error', title: 'Consents submission error' };
      }
      dispatch('notifyHandler', notification);
    },
    async refreshLoggedUser({ dispatch }) {
      try {
        await dispatch('refresh_user');
      } catch {
        dispatch('notifyHandler', { type: 'error', title: 'No required consents. Logging out.' });
        await dispatch('logout');
      }
    },
    // eslint-disable-next-line no-empty-pattern
    notifyHandler({}, notification) {
      Vue.notify(notification);
    },
  },
  getters: {
    user(state) {
      return state.user;
    },
    auth(state) {
      return !!state.user;
    },
    restricted(state) {
      return state.restricted;
    },
    proposals_query(state) {
      return state.proposals_query;
    },
    getLoggedUserAttribute: (state) => (attributePath) => _.get(state.user, attributePath),
    loggedUserId: (state, getters) => getters.getLoggedUserAttribute('_id.$oid'),
  },
};
