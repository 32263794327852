const importSpecification = async (infrastructure) => {
  let specification;
  if (infrastructure === 'synchrotron') {
    specification = import('@/json/beamlineSpecification');
  } else {
    specification = import('@/json/cryoemSpecification');
  }
  return specification;
};

const isProposalOfType = (proposalGeneralPart, type) => proposalGeneralPart.proposal_type === type;

function emitSaveProposal() {
  this.$emit('save-proposal');
}

export {
  importSpecification,
  isProposalOfType,
  emitSaveProposal,
};
