// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import Vuetify from 'vuetify';
import BootstrapVue from 'bootstrap-vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import datepicker from 'vue-date';
import Datetime from 'vue-datetime';
import Vuelidate from 'vuelidate';
import Notifications from 'vue-notification';
import VCalendar from 'v-calendar';
import _ from 'lodash';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { store } from './store';
import { router } from './router';
import App from './App';

import '@/assets/css/global.css';

import 'vuetify/dist/vuetify.min.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'vue-datetime/dist/vue-datetime.css';
import 'vue-multiselect/dist/vue-multiselect.min.css';

// Create all filters and mixins
import '@/filters/sample_risk';
import '@/filters/text_filters';
import '@/mixins/index';

library.add(faCoffee);

// creating the directing removing the root element for all
// v-fragment components
const VueFragment = {
  install(Vue) {
    Vue.directive('fragment', {
      inserted(element) {
        const fragment = document.createDocumentFragment();
        Array.from(element.childNodes).forEach((child) => fragment.appendChild(child));
        element.parentNode.insertBefore(fragment, element);
        element.parentNode.removeChild(element);
      },
    });

    Vue.component('vue-fragment', {
      render(h) {
        return h('div',
          { directives: [{ name: 'fragment' }] },
          this.$slots.default);
      },
    });
  },
};
Vue.use(VueFragment);

Vue.use(Vuetify);
Vue.use(BootstrapVue);
Vue.use(VueAxios, axios);
Vue.use(Datetime);
Vue.use(require('vue-moment'));

Vue.use(Vuelidate);
Vue.use(Notifications);
Vue.use(VCalendar, {
  formats: {
    title: 'MMMM YYYY',
    weekdays: 'W',
    navMonths: 'MMM',
    input: ['YYYY-MM-DD'],
    dayPopover: 'L',
  },
  screens: {
    phone: '576px',
    tablet: '720px',
    laptop: '992px',
    desktop: '1200px',
    desktop_lg: '1400px',
  },
});

Object.defineProperty(Vue.prototype, '$_', { value: _ });

Vue.component('datepicker', datepicker);
Vue.component('icon', FontAwesomeIcon);

// Dev Axios settings
// If active development mode than app can be connected with backend.
// To correct connection backend has CORS feature to get access for if debug flask mode is active
axios.defaults.baseURL = '/backend';
Vue.axios.defaults.withCredentials = true;

axios.interceptors.response.use(
  (response) => response,
  (err) => {
    if (err.config.url.slice(-13) === 'token/refresh') {
      store.dispatch('login/auto_logout')
        .finally(() => {
          router.replace('/login');
        });
    } else if (err.response.status === 401 && err.response.config && !err.config.__isRetry) {
      const originalRequest = err.config;
      originalRequest.__isRetry = true;
      return axios.post('/token/refresh')
        .then(
          () => axios(originalRequest)
            .then(
              (response) => response,
            ),
        );
    } else {
      return Promise.reject(err);
    }
  },
);

Vue.notify({ duration: 5000 });

Vue.filter('custom_pretty', (value) => {
  if (!value) return undefined;
  return value.replace(/_/g, ' ').toLowerCase();
});

// Vue setting
// switch off website tip in development mode
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify: Vuetify,
  render: (h) => h(App),
}).$mount('#app');
