import Vue from 'vue';

Vue.filter('pretty', (value) => value.replace(/_/g, ' ')
  .toLowerCase());

Vue.filter('capitalize', (value) => {
  if (!value) {
    return '';
  }
  const value_str = value.toString();
  return value_str.charAt(0)
    .toUpperCase() + value_str.slice(1);
});
