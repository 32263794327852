import apiService from './api_layer';

export const calls = {
  load_current_call: () => apiService.get('/calls/current'),
  load_calls: () => apiService.get('/calls'),
  get_call: (call_id) => apiService.get(`/calls/${call_id}`),
  save_new_call: (call) => apiService.put('/calls', call),
  save_call_changes: (call) => apiService.post(`/calls/${call._id.$oid}`, call),
  submit_new_call: (call) => apiService.patch('/calls', call),
  submit_call: (call) => apiService.patch(`/calls/${call._id.$oid}`, call),
  remove_call: (call) => apiService.delete(`/calls/${call._id.$oid}`),
};

export const config = {
  loadConfig: (configName) => apiService.get(`/config/${configName}`),
  saveConfig: (conf) => apiService.put(`/config/${conf.name}`, conf),
};

export const users = {
  load_self_affiliations: () => apiService.get('/affiliations/me'),
  loadSelfConsents: () => apiService.get('users/me/consents'),
  updateSelfConsents: (consents) => apiService.post('users/me/consents', consents),
};

export const reviewers = {
  loadReviewersSynchrotron: () => apiService.get('/users/roles/reviewer', { params: { state: 'False' } }),
  loadReviewersCryoem: () => apiService.get('/users/roles/reviewer/cryoem', { params: { state: 'False' } }),
};

export const calendar = {
  submitDocumentReservations: (objectId, dates) => apiService.post(`/documents/${objectId}/cryoem/reservations`, dates),
  loadCalendarConfig: (environment) => apiService.get(`/config/${environment}/calendar`),
  loadCalendarReservations: (environment) => apiService.get(`/documents/${environment}/reservations`),
  submitCalendarConfigDates: (environment, data) => apiService.post(`/config/${environment}_calendar/dates`, data),
};

export const proposalForm = {
  submitProposal: (objectId, proposal) => apiService.patch(`/documents/${objectId}`, proposal),
  submitRapidAccessProposal: (objectId, proposal) => apiService.patch(`/documents/${objectId}/rapid_access`, proposal),
};

export const rapidAccessEvaluation = {
  loadRapidAccessFinalEvaluationProposals:
    (environment) => apiService.get(`/documents/rapid_access/${environment}/inhouse_grade`),
  saveRapidAccessProposalFinalEvaluation:
    (proposalId, evaluation) => apiService.post(`/documents/${proposalId}/rapid_access/inhouse_grade`, evaluation),
  submitRapidAccessProposalFinalEvaluation:
    (proposalId) => apiService.patch(`/documents/${proposalId}/rapid_access/inhouse_grade`),
};

export const usersMePrivileges = {
  loadUsersMePrivilege: (privilegeId) => apiService.get(`/users/me/privileges/${privilegeId}`),
  updateUsersMePrivilegeState:
    (privilegeId, privilegeState) => apiService.patch(`/users/me/privileges/${privilegeId}`, { privilegeState }),
};
