<template>
  <div :class="[{'open-item' : show}, {'active-item' : active}, {'parent-active-item' : childActive}]"
       class="vsm-item">
    <template v-if="!item.child">
      <template v-if="isRouterLink">
        <router-link :disabled="item.disabled"
                     :to="item.href"
                     class="vsm-link">
          <base-material-design-icon v-if="item.icon"
                                     :icon="item.icon"
                                     class="vsm-icon"/>
          <span class="vsm-title">{{ item.title }}</span>
        </router-link>
      </template>
      <template v-else>
        <a :disabled="item.disabled"
           :href="item.href"
           class="vsm-link">
          <base-material-design-icon v-if="item.icon"
                                     :icon="item.icon"
                                     class="vsm-icon"/>
          <span class="vsm-title">{{ item.title }}</span>
        </a>
      </template>
    </template>
    <template v-else>
      <div class="vsm-link"
           @click="toggleDropdown">
        <base-material-design-icon v-if="item.icon"
                                   :icon="item.icon"
                                   class="vsm-icon"/>
        <span class="vsm-title">{{ item.title }}</span>
        <base-material-design-icon :class="{'open-arrow' : show}"
                                   icon="keyboard_arrow_right"/>
      </div>
      <div class="vsm-dropdown">
        <transition name="show-animation">
          <div v-if="show"
               class="vsm-list">
            <item v-for="(subItem, index) in item.child"
                  :key="index"
                  :item="subItem"/>
          </div>
        </transition>
      </div>
    </template>
  </div>
</template>

<script>
  import BaseMaterialDesignIcon from '@/components/baseComponents/baseIcons/BaseMaterialDesignIcon';
  import Item from './Item';
  import { itemMixin } from '../mixin';

  export default {
    components: { BaseMaterialDesignIcon, Item },
    data() {
      return { show: false };
    },
    mixins: [itemMixin],
    props: { item: Object },
    beforeCreate() {
      this.$options.components.Item = require('./Item.vue').default;
    },
  };
</script>
