<template>
  <div>
    <base-submit-info-modal ref="consentRegisterModal"
                            :confirm-function="submitConsent"
                            :prevent-close="isRegisterConsentSubmitted === false"
                            cancelButtonHidden
                            confirm-button-title="Accept"
                            modal-title="Manage consents">
      <template>
        <p class="warning-p">
          Approval of this consent is necessary in order to use the website.
        </p>
        <clause/>
      </template>
    </base-submit-info-modal>
  </div>
</template>

<script>
  import BaseSubmitInfoModal from '@/components/baseComponents/baseModals/BaseSubmitInfoModal';
  import clause from '@/components/clause/clause';

  export default {
    name: 'ConsentRegisterModal',
    components: {
      clause,
      BaseSubmitInfoModal,
    },
    data() {
      return { isRegisterConsentSubmitted: false };
    },
    methods: {
      openModal() {
        this.$refs.consentRegisterModal.show();
      },
      closeModal() {
        this.$refs.consentRegisterModal.hide();
      },
      async submitConsent() {
        try {
          await this.$store.dispatch('login/updateSelfConsents', { register: { state: true, date: new Date() } });
          this.isRegisterConsentSubmitted = true;
          this.$nextTick(() => {
            this.closeModal();
          });
        } catch {
          this.$notify({ type: 'error', title: 'Approval not saved!' });
        }
      },
    },
  };
</script>

<style scoped>

  .warning-p {
    color: red;
    margin-top: 10px;
  }

</style>
