export class HelpersProperties {
  constructor() {
    this.prop = {};
  }

  getAsObject(...fieldsNames) {
    return Object.fromEntries(
      Object.entries(this.prop)
        .filter(([fieldName]) => fieldsNames.includes(fieldName)),
    );
  }

  getSingleField(fieldName) {
    return this.prop[fieldName];
  }

  async initialize(sourcePath) {
    this.prop = await import(`@/helpers/${sourcePath}`);
  }
}
