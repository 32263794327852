<template>
  <label>
    <p class="font-italic mt-2">According to art. 13 of Regulation (EU) 2016/679 of the European Parliament and of the
      Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and
      on the free movement of such data, and repealing Directive 95/46/EC (General Data Protection Regulation) (called
      GDPR) Jagiellonian University informs, that:</p>
    <ol>
      <li>Your Personal Data Administrator is Jagiellonian University, ul. Gołębia 24, 31-007 Kraków.</li>
      <li>A data protection officer was appointed at the Jagiellonian University. Contact details: 31-007 Kraków, ul.
        Czapskich 4, pok. 25, e-mail: iod@uj.edu.pl, tel. +48 12 663 12 25.
      </li>
      <li>Your personal data will be processed in order to:
        <ol class="list-lower-alpha">
          <li>register in the SOLARIS User Network (SUN) system and obtain e-mail system notifications concerning
            changes in: account status, proposal status and required actions - based on the consent granted by you, in
            accordance with art. 6 par. 1 point a) of the GDPR;
          </li>
          <li>making an assessment of the application, booking the research time on the center's research
            infrastructure, managing the user's account and access to resources of the SOLARIS center, for the purposes
            of publishing the results of the experiment, including NCPS SOLARIS websites and the NCPS SOLARIS
            publication library, and for placing the Your personal data in the annual report published on the NCPS
            SOLARIS website and sent to the Minister of Science and Higher Education - pursuant to art. 6 par. 1 (b) and
            (c) of the GDPR, i.e. when processing is necessary for the performance of a contract to which the data
            subject is party or take action at the request of the data subject before concluding the contract or for
            fulfilling the legal obligation incumbent on the controller;
          </li>
          <li>providing your personal data (name, surname, e-mail) to other users of the SUN system in order to enable
            possibility to be selected as a member of the research team of another user (Proposal Author) - based on the
            consent granted by you, in accordance with art. 6 par. 1 point (a) of the GDPR;
          </li>
          <li>put your personal data, including the image, for promotional and informational purposes in: press, radio,
            televison and Internet, including the SOLARIS UJ website and in social media (e.g. Facebook) - based on the
            consent granted by you, in accordance with art. 6 par. 1 point (a) of the GDPR;
          </li>
          <li>send (by e-mail) information on related events, initiatives, surveys, etc. - based on the consent granted
            by you, in accordance with art. 6 par. 1 point (a) of the GDPR;
          </li>
          <li>send (by e-mail) occasional wishes - based on the consent granted by you, in accordance with art. 6 par. 1
            point (a) of the GDPR;
          </li>
        </ol>
      </li>
      <li>Providing personal data by you is voluntary. The consequence of not providing personal data will be the
        inability to register in SUN system and applying for a research time on Synchrotron.
      </li>
      <li>Your personal data will be kept for the period of your use of the SOLARIS center's infrastructure and services
        for up to 10 years from the date of the last activity in the tele information systems of the center (last
        login), but no longer than until the end of SOLARIS's operation or until you withdraw your consent and then for
        the period of limitation of claims / defense against claims.
      </li>
      <li>You have the right to: access your data and rectify it, as well as the right to remove, limit processing,
        transfer data, object to processing - in cases and on the conditions specified in the GDPR.
      </li>
      <li>You also have the right to withdraw your consent at any time without affecting the legality of the processing
        which was made on the basis of the consent prior to its withdrawal. Withdrawal of consent to the processing of
        personal data can be reported by unchecking proper box in “Manage Your Consents” in “Profile and Affiliation”
        module in SUN. The withdrawal can be also sent:
        <ol class="list-lower-alpha">
          <li>by e-mail to the following address: iso.synchrotron@uj.edu.pl,</li>
          <li>by traditional mail to the following address: ul. Czerwone Maki 98, 30-392 Kraków, Poland</li>
          <li>or withdrawn in person by putting at the headquarters of NCPS SOLARIS UJ, ul. Czerwone Maki 98, 30-392
            Kraków, Poland
          </li>
        </ol>
        <br>
        <ul class="list-style-none">
          <li><p>The consequence of withdrawing consent to the processing of personal data will be the inability to:
            register in the SUN system and thus submit an application in order to use the research time on Synchrotron,
            managing the user's account and access to resources of the SOLARIS center, and the inability to use your
            personal data for purposes indicated in points 3.c-f.</p></li>
        </ul>
      </li>
      <li>Your data may be transferred to entities that process personal data at the request of the Administrator
        (processing entities), among others IT service providers - these entities process data on the basis of an
        agreement with the Administrator and only in accordance with our instructions.
      </li>
      <li>Your data will not be transferred outside the EEA (European Economic Area).</li>
      <li>You have the right to lodge a complaint to the President of the Office for Personal Data Protection if you
        believe that the processing of your personal data violates the provisions of the GDPR.
      </li>
    </ol>
  </label>
</template>

<script>
  export default {
    updated() {
      this.$store.commit('login/restrict', true);
    },
    destroyed() {
      this.$store.commit('login/restrict', false);
    },
  };
</script>

<style scoped>
  .list-lower-alpha {
    list-style-type: lower-alpha;
  }

  .list-style-none {
    list-style-type: none;
  }
</style>
