import Vue from 'vue';

const get_bigger = (old_value, new_value) => (old_value >= new_value ? old_value : new_value);

const calculate_sample_risk = (sample) => {
  let state_degree = 0;
  if (sample.physical_state.toLowerCase() === 'solid') {
    if (sample.release_dust_particles || sample.sublime) {
      state_degree = get_bigger(state_degree, 2);
    } else {
      state_degree = get_bigger(state_degree, 1);
    }
    if (sample.release_nanoparticles) state_degree = get_bigger(state_degree, 3);
  }
  if (sample.physical_state.toLowerCase() === 'liquid') {
    if (sample.volatile) {
      state_degree = get_bigger(state_degree, 3);
    } else {
      state_degree = get_bigger(state_degree, 2);
    }
  }
  if (sample.physical_state.toLowerCase() === 'gas') {
    state_degree = get_bigger(state_degree, 3);
  } else {
    state_degree = get_bigger(state_degree, 1);
  }

  let risk_degree = 0;
  if (sample.sample_safety.indexOf('explosive') > -1) {
    risk_degree = get_bigger(risk_degree, 3);
  }
  if (sample.sample_safety.indexOf('oxidizing') > -1) {
    risk_degree = get_bigger(risk_degree, 3);
  }
  if (sample.sample_safety.indexOf('biological') > -1) {
    risk_degree = get_bigger(risk_degree, 3);
  }
  if (sample.sample_safety.indexOf('radioactive') > -1) {
    risk_degree = get_bigger(risk_degree, 2);
  }
  if (sample.sample_safety.indexOf('corrosive') > -1) {
    risk_degree = get_bigger(risk_degree, 3);
  }
  if (sample.sample_safety.indexOf('none_of_these') > -1) {
    risk_degree = get_bigger(risk_degree, 1);
  }

  if (sample.sample_safety.indexOf('biological') > -1) {
    if (sample.selected_biological_risks.indexOf('animal_tissue')
      > -1) {
      risk_degree = get_bigger(risk_degree, 1);
    }
    if (sample.selected_biological_risks.indexOf('toxin_production')
      > -1) {
      risk_degree = get_bigger(risk_degree, 1);
    }
    if (sample.selected_biological_risks.indexOf('plant_sample')
      > -1) {
      risk_degree = get_bigger(risk_degree, 1);
    }
    if (sample.selected_biological_risks.indexOf('allergic_effects')
      > -1) {
      risk_degree = get_bigger(risk_degree, 1);
    }
    if (sample.selected_biological_risks.indexOf('human_tissue')
      > -1) {
      risk_degree = get_bigger(risk_degree, 2);
    }
    if (sample.selected_biological_risks.indexOf('gmm')
      > -1) {
      risk_degree = get_bigger(risk_degree, 2);
    }
    if (sample.selected_biological_risks.indexOf('biological_agent')
      > -1) {
      risk_degree = get_bigger(risk_degree, 2);
    }
  }

  return (state_degree * risk_degree);
};

Vue.filter(
  'sample_risk',
  (sample) => {
    const result = {
      0: 'No risk',
      1: '1 - negligible risk',
      2: '2 - low risk',
      3: '3 - medium risk',
      4: '4 - medium risk',
      6: '6 - high risk',
      9: '9 - high risk',
    };
    try {
      return result[calculate_sample_risk(sample)];
    } catch (e) {
      return 'There is not enough data!';
    }
  },
);
