import { config as urlLayer } from '@/api/url_layer';

export const config = {
  namespaced: true,
  state: { databaseHome: {} },
  mutations: {
    setDatabaseHome(state, configuration) {
      state.databaseHome = configuration;
    },
    setDatabaseHomeConfig(state, configuration) {
      state.databaseHome.config = { ...state.databaseHome.config, ...configuration };
    },
  },
  actions: {
    async getDatabaseHome({ commit }) {
      try {
        const { data } = await urlLayer.loadConfig('home');
        commit('setDatabaseHome', data);
        return true;
      } catch (error) {
        return false;
      }
    },
    async putDatabaseHome({ commit, state }, configuration) {
      try {
        commit('setDatabaseHomeConfig', configuration);
        await urlLayer.saveConfig(state.databaseHome);
        return true;
      } catch (error) {
        return false;
      }
    },
  },
  getters: {
    homeConfigFooter: (state) => state.databaseHome.config.footer,
    homeConfigCards: (state) => state.databaseHome.config.cards,
  },
};
