const registerConsentText = 'I consent to the processing of my personal data for registration in '
  + 'the SOLARIS User Network (SUN) and obtain e-mail system notifications concerning changes in: '
  + 'account status, proposal status and required actions in accordance with the Regulation of the European '
  + 'Parliament and of the Council (EU) 2016/679 of 27 April 2016 and in accordance with the '
  + '<a class="red-text"\n'
  + '    href="#/user/regulations">'
  + '    <i><u>\n'
  + '        information clause\n'
  + '    </u></i>'
  + '</a>'
  + '\n attached to my consent.';

const promotionConsentText = 'I agree to the processing of my personal data, including my face '
  + 'image, for promotional and informational purposes in: press, radio, televison and Internet, including '
  + 'the SOLARIS UJ website and in social media (Facebook) in accordance with the Act of 10 May 2018 on '
  + 'the protection of personal data (Journal of Laws of May 24, 2018, item 1000) and in accordance with the '
  + 'Regulation of the European Parliament and of the Council (EU) 2016/679 of 27 April 2016 '
  + 'and in accordance with the information clause attached to my consent.<br>\n'
  + '<hr>\n'
  + 'Furthermore, I declare that pursuant to art. 81 par. 1 of the act on copyright and related rights dated '
  + 'February 4, 1994 (Journal of Laws of 2004, item 880, as amended) I agree to use my image free of charge '
  + 'for the abovementioned purpose. In connection with the use of my image I will renounce:<br>\n'
  + '<ol>\n'
  + '    <li>the right to control and to approve the use of my image every time, including the right of approval '
  + '        in relation to the final form of the Jagiellonian University materials in which my image will be used;'
  + '    </li>\n'
  + '    <li>the right to always indicate me as a person visible on the Jagiellonian University materials, in '
  + '        particular to indicate my name and surname;'
  + '    </li>\n'
  + ' </ol>\n'
  + 'I understand that at any time I have the opportunity to inspect the material containing my image and it '
  + 'does not violate my personal rights.';

const teamShareConsentText = 'I consent to providing my personal data (name, surname, e-mail) to '
  + 'other users of the SUN system in order to enable possibility to be selected as a member of '
  + 'the research team of another user (Proposal Author) - '
  + '<span class="red--text">\n'
  + '    Lack of consent results in the inability to be invited to the research team;'
  + '</span>';

const communicationConsentText = 'I agree to receive (by e-mail) information on related events, '
  + 'initiatives, surveys, etc.;';

const wishesConsentText = 'I agree to receive (by e-mail) occasional wishes;';

export {
  registerConsentText,
  promotionConsentText,
  teamShareConsentText,
  communicationConsentText,
  wishesConsentText,
};
