import Vue from 'vue';
import { hasProperty, capitalizeText } from '@/helpers';
import { send_pdf_to_user } from '@/helpers/pdfDownloadFunctions';

export const globalMixin = {
  methods: {
    hasProperty,
    created_at(object) {
      if (object.states.history !== []) {
        if (object.states.history.length > 0) {
          return object.states.history[0].date;
        }
        if (object.states.date !== undefined) {
          return object.states.date;
        }
      } else {
        return object.states.date;
      }
    },
    sendPatchRequest(url, body) {
      return this.axios.patch(url, body);
    },
    // helper method to update call_filter and proposal filter logic
    get_parent_by_id(id) {
      let component = null;
      let parent = this.$parent;
      while (parent && !component) {
        if (parent.id === id) {
          component = parent;
        }
        parent = parent.$parent;
      }
      return component;
    },
    refresh_proposals_filter(proposals) {
      this.proposals = proposals;
      this.$emit('setup_proposal', this.proposals);
    },
    call_change(call) {
      // this method is use to change selected call in the parent
      // hardcoded properties name -> not generic maybe change this logic!
      this.selected_call = call;
    },

    is_text_valid(text, regex) {
      return (text === '') ? false : !!(regex.test(text));
    },

    capitalizeText,

    getUserFullName({ first_name, last_name }) {
      return `${first_name} ${last_name}`;
    },

    is_password_valid(password, password_confirmation, regex) {
      if (password > 7) {
        if (this.is_text_valid(password, regex)) {
          return password === password_confirmation;
        }
      }
      // null is returned to not show input state if password is empty
      return null;
    },

    get_deep_prop(obj, props) {
      return props.split('.').reduce((acc, p) => {
        // if the accumulator is something
        // then lookup the next nested property
        // otherwise return undefined
        if (acc == undefined) return '';
        if (this.hasProperty(acc, p)) {
          return acc[p];
        }
        return null;
      }, obj); // the initial accumulator is the object
    },

    generate_pdf(id) {
      this.axios.get(`/render_pdf/${id}`, { responseType: 'arraybuffer' })
        .then((response) => {
          const url = response.request.responseURL;
          send_pdf_to_user(url);
        });
    },

    async download_document_pdf(object_id) {
      const response = await this.axios.get(`/documents/${object_id}/download_pdf`);
      const { msg } = response.data;
      if (msg) {
        this.$notify({ type: 'error', title: msg });
      } else {
        send_pdf_to_user(response.request.responseURL);
      }
    },

    async downloadPdf(pdfId) {
      try {
        const response = await this.axios.get(`/documents/download/${pdfId}`);
        const { msg } = response.data;
        if (msg) {
          this.$notify({ type: 'error', title: msg });
        } else {
          send_pdf_to_user(response.request.responseURL);
        }
      } catch {
        this.$notify({ type: 'error', title: 'Communication error' });
      }
    },

    download_zip(...args) {
      const params = {};
      args.forEach((item) => {
        const entry = Object.entries(item)[0];
        params[entry[0]] = entry[1];
      });
      return this.axios({
        url: `/documents/${this.environment}/zip`,
        method: 'GET',
        responseType: 'blob',
        params,
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'proposals.zip');
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        });
    },

    calculate_row_index(index, per_page, current_page) {
      return index + (per_page * (current_page - 1));
    },

    close_modal_emit() {
      // emit signal to parent (of x_model components)
      this.$emit('closeModal');
    },
    close_modal_slot() {
      // close modal by ref setup in the b-modal directive (this.modal_ref)
      // must be setup the component data modal_ref="ModalRef" and
      // :ref="modal_ref" in the b-modal definition
      this.$refs[this.modal_ref].hide();
    },
    clean_data() {
      this.review = {};
    },
    isEmpty(obj) {
      for (const key in obj) {
        if (this.hasProperty(obj, key)) {
          return false;
        }
      }
      return true;
    },
    hexToRgb(hex_color) {
      const color = hex_color.replace('#', '');
      const r = parseInt(color.substring(0, 2), 16);
      const g = parseInt(color.substring(2, 4), 16);
      const b = parseInt(color.substring(4, 6), 16);
      return `rgb(${r},${g},${b})`;
    },
    emitSetupProposal(event) {
      this.$emit('setup_proposal', event);
    },
  },
};

Vue.mixin(globalMixin);
