<template>
  <div id="app">
    <div id="content"
         :class="{'collapsed' : collapsed, 'logged': show}">
      <navbar v-if="show"/>
      <router-view :key="$route.fullPath"/>
      <sidebar-menu v-if="show"
                    :collapsed="collapsed"
                    :width="'250px'"
                    :widthCollapsed="'50px'"
                    class="sidebar-menu"
                    @collapse="onCollapse"/>
    </div>
    <notifications position="top right"/>
  </div>
</template>

<script>
  import SidebarMenu from '@/components/vue-sidebar-menu/components/SidebarMenu';
  import { mapGetters } from 'vuex';
  import navbar from './components/navbar';

  export default {
    name: 'App',
    components: {
      navbar,
      SidebarMenu,
    },
    data() {
      return { collapsed: true };
    },
    methods: {
      onCollapse(val) {
        this.collapsed = val;
      },
    },
    computed: {
      ...mapGetters('login', ['auth', 'restricted']),
      isAfterLoginView() {
        const routeMetaHasKey = !this.isEmpty(this.$route.meta);
        const whenLoggedAllowFromNotInMeta = !this.hasProperty(this.$route.meta, 'whenLoggedAllowFrom');
        return routeMetaHasKey && whenLoggedAllowFromNotInMeta;
      },
      show() {
        return this.auth && !this.restricted && this.isAfterLoginView;
      },
    },
    created() {
      this.$store.dispatch('login/auto_login');
    },
  };

</script>

<style lang="scss">
  .sidebar-menu {
    height: calc(100vh - calc(100vh - 100%));
    z-index: 2;
  }

  :focus {
    outline: none;
    box-shadow: none;
  }

  #app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -webkit-background-size: cover;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
  }

  #content.logged {
    padding-left: 250px;
  }

  #content.logged.collapsed {
    padding-left: 50px;
  }

  #content {
    padding-left: 0;
  }

  .danger-color-red {
    color: $color-red-light;
  }

  .vdatetime-input {
    padding: 5.25px 10.5px;
    border-radius: 3px;
    background-color: white;
  }

  .input-container {
    margin: 1rem;
  }

  .modal-full-response {
    width: fit-content !important;
    max-width: 99% !important;
  }

  .modal-xxl {
    width: 85% !important;
    max-width: 99% !important;
  }

  .page-item.active .page-link {
    z-index: 0 !important;
  }

  .preLine {
    white-space: pre-line;
  }

  @font-face {
    font-family: "Harabara Bold";
    src: url("assets/fonts/EkMukta-Bold.ttf"),
    url("assets/fonts/EkMukta-ExtraBold.ttf"),
    url("assets/fonts/EkMukta-ExtraLight.ttf"),
    url("assets/fonts/EkMukta-Light.ttf"),
    url("assets/fonts/EkMukta-Medium.ttf"),
    url("assets/fonts/EkMukta-Regular.ttf"),
    url("assets/fonts/EkMukta-SemiBold.ttf");
  }

  .div--button {
    background-color: rgb(7, 55, 99);
    border-radius: 5px;
    color: white;
    text-align: center;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
  }

  .button-before-login {
    margin-top: 1rem !important;
    background-color: $color-blue-dark !important;
    border: none !important;
    color: white !important;
    font-family: Arial, 'Avenir', Helvetica, sans-serif !important;
    font-weight: bold !important;
  }

  .button-before-login:hover:enabled {
    background-color: #5E8BC4 !important;
  }

  // cryo calendar buttons
  // !important is used because of wrong class ordering bug of extract-text-webpack-plugin
  .btn-teal {
    background-color: teal !important;
    opacity: 0.8;
    border-color: white !important;
    border-width: 3px !important;
    color: whitesmoke !important;
    border-radius: 10px !important;
  }

  .btn.btn-teal.active:not(.toggle), .btn.btn-teal:focus:not(.toggle) {
    background-color: white !important;
    opacity: 1;
    border-color: white !important;
    color: teal !important;
    box-shadow: 0.2rem 0 0 0 rgba(1, 153, 153, .25) !important;
  }

  .btn-teal:hover {
    opacity: 1.7;
    color: whitesmoke;
  }

  .progress-bar-teal {
    background-color: teal !important;
    opacity: 0.8;
    border-color: white !important;
    color: whitesmoke !important;
    overflow: visible !important; //needed to show progressbar label when value=0 === div width: 0px
  }

  .progress-whitesmoke {
    background-color: whitesmoke !important;
  }

  .btn-teal:disabled {
    background-color: whitesmoke !important;
    color: gray !important;
    border-radius: 10px !important;
  }

  .btn-teal.disabled:not(.skip) {
    height: 0;
    margin: 0;
    padding: 0;
    border: 0;
    z-index: -1000;
  }

  // cryo calendar b-card
  @media only screen and (max-width: 1199px) {
    .expand {
      width: 100%;
      margin: 0;
      align-items: center;
    }
  }

  @media only screen and (min-width: 1200px) {
    .expand {
      margin-right: 4px;
    }
  }
</style>
