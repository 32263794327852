<template>
  <b-tab :title="tabTitle"
         title-link-class="text-info">

    <base-div-with-agreement v-if="isPrivilegeLoaded"
                                  v-model="privilegeCheckboxValue"
                                  :div-text="filledPrivilegeText"
                                  :warning-text="warningText"/>
    <p v-else
       class="error-text"
       v-text="privilegeNotLoadedText"/>

  </b-tab>
</template>

<script>
  import BaseDivWithAgreement from '@/components/baseComponents/BaseDivWithAgreement';

  export default {
    name: 'PrivilegeAgreementTab',
    components: { BaseDivWithAgreement },
    props: {
      privilegeId: {
        type: String,
        required: true,
      },
      selectedPrivilegeId: {
        type: String,
        required: true,
      },
      submitTrigger: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        // elements change during component life
        currentPrivilegeState: '',
        privilegeCheckboxValue: false,
        // elements set only after privilege upload
        privilege: {},
        databasePrivilegeState: '',
        filledPrivilegeText: '',
        warningAcceptText: '',
        warningRejectText: '',
        // static elements
        acceptedState: 'ACCEPTED',
        rejectedState: 'REJECTED',
        waitingState: 'WAITING FOR CONFIRMATION',
        warningNoStateChangeText: '',
        privilegeNotLoadedText: 'Privilege cannot be loaded, try again or contact the SOLARIS User Office.',
      };
    },
    computed: {
      isPrivilegeAccepted() {
        return this.currentPrivilegeState === this.acceptedState;
      },
      isPrivilegeLoaded() {
        return !!Object.keys(this.privilege).length;
      },
      tabTitle() {
        if (this.isPrivilegeLoaded) {
          const { type, name } = this.privilege.granted_privilege;
          return `${type} ${name}`;
        }
        return 'Loading failed';
      },
      warningText() {
        if (this.databasePrivilegeState === this.acceptedState && this.currentPrivilegeState === this.rejectedState) {
          return this.warningRejectText;
        }
        if (this.databasePrivilegeState !== this.acceptedState && this.isPrivilegeAccepted) {
          return this.warningAcceptText;
        }
        return this.warningNoStateChangeText;
      },
    },
    watch: {
      privilegeCheckboxValue: {
        handler(newVal) {
          this.currentPrivilegeState = newVal ? 'ACCEPTED' : 'REJECTED';
        },
      },
      submitTrigger: {
        handler() {
          if (this.privilegeId === this.selectedPrivilegeId && this.isPrivilegeLoaded) {
            this.submitNewPrivilegeState();
          }
        },
      },
    },
    async created() {
      await this.loadPrivilege();
      if (this.isPrivilegeLoaded) {
        this.fillConstDataConnectedToPrivilege();
        this.currentPrivilegeState = this.databasePrivilegeState;
        this.privilegeCheckboxValue = this.isPrivilegeAccepted;
      }
    },
    methods: {
      async loadPrivilege() {
        await this.$store.dispatch('usersMePrivileges/loadUsersMePrivilege', this.privilegeId);
        this.privilege = this.$store.getters['usersMePrivileges/privilegeById'](this.privilegeId);
      },
      fillConstDataConnectedToPrivilege() {
        this.constructWarningTexts(this.privilege.granted_privilege);
        this.databasePrivilegeState = this.privilege.states.name;
        this.createFilledPrivilegeText(this.privilege.text);
      },
      constructWarningTexts({ type, name }) {
        const warningStartPart = 'WARNING: Your reaction will result in';
        const warningEndPart = 'Re-login will be required to see changes.';

        this.warningAcceptText = `${warningStartPart} becoming a ${name}. ${warningEndPart}`;
        this.warningRejectText = `${warningStartPart} losing ${name} ${type}. ${warningEndPart}`;
      },
      createFilledPrivilegeText(textObject) {
        const separatorText = '<hr/><br/>';
        Object.values(textObject).forEach((privilegeText, idx) => {
          if (idx === 0) {
            this.filledPrivilegeText = privilegeText;
          } else {
            this.filledPrivilegeText = `${this.filledPrivilegeText} ${separatorText} ${privilegeText}`;
          }
        });
      },
      async submitNewPrivilegeState() {
        const submitData = {
          privilegeId: this.privilegeId,
          privilegeState: this.currentPrivilegeState,
        };
        await this.$store.dispatch('usersMePrivileges/updateUsersMePrivilegeState', submitData);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .error-text {
    margin-top: 20px;
    color: $color-red-light;
  }
</style>
