<template>
  <div
    :class="[{'first-item' : firstItem}, {'open-item' : show}, {'active-item' : active}, {'parent-active-item' : childActive}]"
    class="vsm-item"
    @mouseenter="mouseEnter($event)">
    <template v-if="!item.child">
      <template v-if="isRouterLink">
        <router-link :disabled="item.disabled"
                     :to="item.href"
                     class="vsm-link"
                     @click.native="clickEvent">
          <base-material-design-icon v-if="item.icon"
                                     :icon="item.icon"
                                     class="vsm-icon"/>
          <span v-if="!isCollapsed"
                class="vsm-title">{{ item.title }}</span>
        </router-link>
      </template>
      <template v-else>
        <a :disabled="item.disabled"
           :href="item.href"
           class="vsm-link">
          <base-material-design-icon v-if="item.icon"
                                     :icon="item.icon"
                                     class="vsm-icon"/>
          <span v-if="!isCollapsed"
                class="vsm-title">{{ item.title }}</span>
        </a>
      </template>
    </template>
    <template v-else>
      <div class="vsm-link"
           @click="toggleDropdown">
        <base-material-design-icon v-if="item.icon"
                                   :icon="item.icon"
                                   class="vsm-icon"/>
        <template v-if="!isCollapsed">
          <span class="vsm-title">{{ item.title }}</span>
          <!--<i class="vsm-arrow" :class="{'open-arrow' : show}" ></i>-->
          <base-material-design-icon :class="{'open-arrow' : show}"
                                     class="vsm-arrow"
                                     icon="keyboard_arrow_right"/>
        </template>
      </div>
      <div v-if="!isCollapsed"
           class="vsm-dropdown">
        <transition name="show-animation">
          <div v-if="show"
               class="vsm-list">
            <sub-item v-for="(subItem, index) in item.child"
                      :key="index"
                      :item="subItem"/>
          </div>
        </transition>
      </div>
    </template>
  </div>
</template>

<script>
  import BaseMaterialDesignIcon from '@/components/baseComponents/baseIcons/BaseMaterialDesignIcon';
  import SubItem from './SubItem';
  import { itemMixin } from '../mixin';

  export default {
    data() {
      return { show: false };
    },
    props: {
      item: {
        type: Object,
        required: true,
      },
      firstItem: {
        type: Boolean,
        default: false,
      },
      isCollapsed: { type: Boolean },
    },
    components: { BaseMaterialDesignIcon, SubItem },
    mixins: [itemMixin],
    methods: {
      mouseEnter(event) {
        if (this.isCollapsed && this.firstItem) {
          this.$parent.$emit('mouseEnterItem', {
            item: this.item,
            pos:
              event.currentTarget.getBoundingClientRect().top
              - this.$parent.$el.getBoundingClientRect().top,
            height: this.$el.offsetHeight,
          });
        }
      },
      clickEvent() {
        if (this.isCollapsed && this.firstItem) {
          this.$parent.$emit('clickItem');
        }
      },
    },
  };
</script>
