<template>
  <div>
    <base-submit-info-modal ref="consentsModal"
                            :confirm-function="handleSubmitClick"
                            :prevent-close="!areRequiredConsentsSubmitted"
                            confirm-button-title="Submit consents"
                            modal-title="Manage consents"
                            @close-error-detected="showCloseConfirmationModal">

      <template v-if="isReady">

        <base-div-with-agreement v-model="visualUserConsents.register.state"
                                 :div-text="registerConsentText"/>

        <p v-if="visualUserConsents.register.state"
           class="warning-p">
          WARNING: Unchecking will result in account deactivation
        </p>
        <p v-else
           class="warning-p">
          WARNING: Re-login may be required to see changes
        </p>

        <hr>

        <base-div-with-agreement v-model="visualUserConsents.promotion.state"
                                 :div-text="promotionConsentText"/>
        <hr>

        <base-div-with-agreement v-model="visualUserConsents.team_share.state"
                                 :div-text="teamShareConsentText"/>
        <hr>

        <base-div-with-agreement v-model="visualUserConsents.communication.state"
                                 :div-text="communicationConsentText"/>
        <hr>

        <base-div-with-agreement v-model="visualUserConsents.wishes.state"
                                 :div-text="wishesConsentText"/>

      </template>
    </base-submit-info-modal>

    <base-submit-danger-modal ref="closeConfirmationModal"
                              :confirm-function="logout"
                              confirm-button-title="Yes, I am sure"
                              modal-title="Are you sure?">
      <p>
        Closing modal without checking the registration consent will result in log out.
      </p>
      <p class="warning-p">
        Remember of submitting selected consents before exit.
      </p>
    </base-submit-danger-modal>

    <base-submit-danger-modal ref="submitConfirmationModal"
                              :confirm-function="submitConsents"
                              confirm-button-title="Yes, I am sure"
                              modal-title="Are you sure?">
      <p class="warning-p">
        Registration consent is unchecked. Submission will result in account deactivation.
      </p>
    </base-submit-danger-modal>
  </div>
</template>

<script>
  import BaseSubmitInfoModal from '@/components/baseComponents/baseModals/BaseSubmitInfoModal';
  import BaseSubmitDangerModal from '@/components/baseComponents/baseModals/BaseSubmitDangerModal';
  import BaseDivWithAgreement from '@/components/baseComponents/BaseDivWithAgreement';
  import {
    registerConsentText,
    promotionConsentText,
    teamShareConsentText,
    communicationConsentText,
    wishesConsentText,
  } from '@/helpers/consents';

  export default {
    name: 'ConsentsModal',
    components: {
      BaseSubmitInfoModal,
      BaseSubmitDangerModal,
      BaseDivWithAgreement,
    },
    data() {
      return {
        visualUserConsents: {},
        dataBaseUserConsents: {},
        registerConsentText,
        promotionConsentText,
        teamShareConsentText,
        communicationConsentText,
        wishesConsentText,
      };
    },
    computed: {
      areChangesSubmitted() {
        return this.$_.isEqual(this.dataBaseUserConsents, this.visualUserConsents);
      },
      areRequiredConsentsSelected() {
        return this.isReady ? this.visualUserConsents.register.state : false;
      },
      areRequiredConsentsSubmitted() {
        return this.isReady ? this.dataBaseUserConsents.register.state : false;
      },
      isReady() {
        return !this.isEmpty(this.visualUserConsents);
      },
    },
    methods: {
      showCloseConfirmationModal() {
        this.$refs.closeConfirmationModal.show();
      },
      async handleSubmitClick() {
        if (!this.areChangesSubmitted) {
          if (!this.areRequiredConsentsSelected) {
            this.$refs.submitConfirmationModal.show();
          } else {
            await this.submitConsents();
          }
        } else {
          this.$notify({ type: 'error', title: 'No changes detected' });
        }
      },
      async loadLoggedUserConsents() {
        this.dataBaseUserConsents = {};
        this.visualUserConsents = {};
        try {
          await this.$store.dispatch('login/loadSelfConsents');
          this.dataBaseUserConsents = this.$store.getters['login/getLoggedUserAttribute']('consents');
          this.visualUserConsents = this.$_.cloneDeep(this.dataBaseUserConsents);
        } catch {
          this.$notify({ type: 'error', title: 'Cannot load consents' });
        }
      },
      async logout() {
        await this.$store.dispatch('login/logout');
        await this.$router.push('/login');
      },
      openModal() {
        this.loadLoggedUserConsents();
        this.$refs.consentsModal.show();
      },
      async submitConsents() {
        await this.$store.dispatch('login/updateSelfConsents', this.visualUserConsents);
        await this.loadLoggedUserConsents();
      },
    },
  };
</script>

<style scoped>

  .warning-p {
    color: red;
    margin-top: 10px;
  }

</style>
