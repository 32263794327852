<template>
  <div :class="[!isCollapsed ? 'vsm-default' : 'vsm-collapsed']"
       :style="{'width': sidebarWidth}"
       class="v-sidebar-menu"
       @mouseleave="mouseLeave">

    <div :style="[{'height' : '100%'}, {'overflow' : 'hidden auto'}]"
         class="vsm-list">
      <div class="logo">

        <b-img v-if="isCollapsed"
               :src="require(`@/assets/solaris-icon.svg`)"
               alt="Solaris logo"
               class="logo-small"
               fluid/>

        <b-img v-else
               :src="require(`@/assets/solaris-logo-en.svg`)"
               alt="Solaris logo"
               class="logo-full"
               fluid/>
      </div>

      <item v-for="(item, index) in menu"
            :key="index"
            :firstItem="true"
            :isCollapsed="isCollapsed"
            :item="item"/>
    </div>

    <div v-if="isCollapsed"
         :style="isCollapsedStyle">

      <mobile-item :item="mobileItem"/>

      <transition name="slide-animation">
        <div v-if="mobileItem"
             :style="mobileItemStyle"
             class="vsm-mobile-bg"/>
      </transition>

      <div
        :style="dropdownStyle"
        class="vsm-dropdown">
        <transition name="show-animation">
          <div v-if="mobileItem && mobileItem.child"
               class="vsm-list">
            <sub-item v-for="(subItem, index) in mobileItem.child"
                      :key="index"
                      :item="subItem"/>
          </div>
        </transition>
      </div>
    </div>

    <button class="collapse-btn"
            @click="toggleCollapse">
      <base-material-design-icon v-if="isCollapsed"
                                 icon="keyboard_arrow_right"/>
      <base-material-design-icon v-else
                                 icon="keyboard_arrow_left"/>
    </button>

  </div>
</template>

<script>
  import BaseMaterialDesignIcon from '@/components/baseComponents/baseIcons/BaseMaterialDesignIcon';
  import Item from './Item';
  import SubItem from './SubItem';
  import MobileItem from './MobileItem';

  export default {
    name: 'SidebarMenu',
    components: {
      BaseMaterialDesignIcon,
      Item,
      SubItem,
      MobileItem,
    },
    props: {
      collapsed: {
        type: Boolean,
        default: false,
      },
      width: {
        type: String,
        default: '350px',
      },
      widthCollapsed: {
        type: String,
        default: '50px',
      },
    },
    data() {
      return {
        menu: [],
        sidebarWidth: this.collapsed ? this.widthCollapsed : this.width,
        isCollapsed: this.collapsed,
        mobileItem: null,
        mobileItemPos: 0,
        mobileItemHeight: 0,
        closeTimeout: null,
      };
    },
    computed: {
      isCollapsedStyle() {
        return [
          { position: 'absolute' },
          { top: `${this.mobileItemPos}px` },
          { left: '0px' },
          { 'z-index': 30 },
          { width: this.width }];
      },
      mobileItemStyle() {
        return [
          { position: 'absolute' },
          { left: '0px' },
          { right: '0px' },
          { top: '0px' },
          { height: `${this.mobileItemHeight}px` },
        ];
      },
      dropdownStyle() {
        return [
          { position: 'absolute' },
          { top: `${this.mobileItemHeight}px` },
          { left: this.sidebarWidth }, { right: '0px' },
          { 'max-height': `calc(100vh - ${this.mobileItemPos + this.mobileItemHeight}px)` },
          { 'overflow-y': 'auto' },
        ];
      },
    },
    created() {
      this.$on('mouseEnterItem', (val) => {
        this.mobileItem = val.item;
        this.mobileItemPos = val.pos;
        this.mobileItemHeight = val.height;
      });

      this.$on('clickItem', () => {
        if (this.closeTimeout) clearTimeout(this.closeTimeout);
        this.closeTimeout = setTimeout(() => {
          this.mouseLeave();
        }, 600);
      });

      this.axios.get('/config/menu')
        .then((response) => {
          this.menu = response.data.config;
        })
        .catch((error) => console.log(error));
    },
    methods: {
      mouseLeave() {
        this.mobileItem = null;
      },
      toggleCollapse() {
        this.isCollapsed = !this.isCollapsed;
        this.sidebarWidth = this.isCollapsed ? this.widthCollapsed : this.width;
        this.$emit('collapse', this.isCollapsed);
      },
    },
  };
</script>

<style lang="scss">
  @import '../styles/main.scss';
</style>
