import Vue from 'vue';
import { hasProperty } from '@/helpers';
import { calls as url_layer } from '../api/url_layer';

export const calls = {
  namespaced: true,
  state: {
    current_call: [],
    calls: [],
  },
  mutations: {
    set_current_call(state, current_call) {
      state.current_call = current_call.current_call;
    },
    set_calls(state, calls_to_set) {
      state.calls = calls_to_set;
    },
    replace_call(state, { call, call_index }) {
      Vue.set(state.calls, call_index, call);
    },
    add_call(state, call) {
      state.calls.push(call);
    },
    sort_calls(state) {
      const sort_key = 'start_submission';
      state.calls = state.calls.sort((b, a) => new Date(a[sort_key]) - new Date(b[sort_key]));
    },
  },
  actions: {
    load_current_call({ commit }) {
      return url_layer.load_current_call()
        .then((response) => {
          commit('set_current_call', { current_call: response.data });
          return Promise.resolve(response);
        });
    },
    load_calls({ commit }) {
      return url_layer.load_calls()
        .then((response) => {
          commit('set_calls', response.data);
          commit('sort_calls');
          return Promise.resolve(response);
        });
    },
    get_call({ commit, getters }, call_id) {
      return url_layer.get_call(call_id)
        .then((response) => {
          let idx = getters.call_index_by_id(call_id);
          if (idx !== -1) {
            commit('replace_call', {
              call: response.data,
              call_index: idx,
            });
          } else {
            idx = getters.call_index_by_all(response.data);
            if (idx !== -1) {
              commit('replace_call', {
                call: response.data,
                call_index: idx,
              });
            } else {
              commit('add_call', response.data);
            }
          }
          return Promise.resolve(response);
        });
    },
    save_new_call({}, call) {
      return url_layer.save_new_call(call)
        .then((response) => Promise.resolve(response));
    },
    save_call_changes({}, call) {
      return url_layer.save_call_changes(call);
    },
    submit_new_call({}, call) {
      return url_layer.submit_new_call(call);
    },
    submit_call({}, call) {
      return url_layer.submit_call(call);
    },
    remove_call({}, call) {
      return url_layer.remove_call(call);
    },
  },
  getters: {
    current_call(state) {
      return state.current_call;
    },
    current_call_end_stations: (state) => (
      state.current_call.length > 0 ? state.current_call[0].environments : []
    ),
    current_call_id: (state) => (
      state.current_call.length > 0 ? state.current_call[0]._id.$oid : -1
    ),
    call_index_by_id: (state) => (call_id) => state.calls.findIndex(
      (el) => (hasProperty(el, '_id') ? el._id.$oid === call_id : false),
    ),
    call_index_by_all: (state) => (new_call) => state.calls.findIndex(
      (el) => el.name === new_call.name
        && el.number === new_call.number
        && el.start_submission === new_call.start_submission
        && el._id === undefined,
    ),
    calls_as_object: (state) => (key_field = 'name') => {
      const obj_calls = {};
      state.calls.forEach((el) => {
        obj_calls[el[key_field]] = el;
      });
      return obj_calls;
    },
  },
};
