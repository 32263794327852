<template>
  <div>
    <b-row>
      <b-col>
        <div class="alert alert-info">
          <p v-html="divText"/>
        </div>
      </b-col>
    </b-row>

    <b-row no-gutters>
      <p class="mr-2">
        I agree
      </p>

      <b-form-checkbox v-model="agreementState"
                       :state="agreementState">

        <div class="consent-feedback"
             v-text="agreementStateText"/>

      </b-form-checkbox>
    </b-row>

    <b-row no-gutters v-if="warningText">
      <p class="red--text"
         v-text="warningText"/>
    </b-row>
  </div>
</template>

<script>
  export default {
    name: 'BaseDivWithAgreement',
    props: {
      divText: {
        type: String,
        default: '',
      },
      value: {
        type: Boolean,
        default: null,
      },
      warningText: {
        type: String,
        default: '',
      },
    },
    data() {
      return { agreementState: this.value };
    },
    computed: {
      agreementStateText() {
        return `currently ${this.agreementState ? 'agreed' : 'not agreed'}`;
      },
    },
    watch: {
      agreementState: {
        handler() {
          this.$emit('input', this.agreementState);
        },
      },
      value: {
        handler() {
          this.agreementState = this.value;
        },
      },
    },
  };
</script>

<style lang="scss" scoped>

  .alert {
    margin-bottom: 10px;
    margin-top: 10px;
    padding: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
    height: auto;
    text-align: justify;
    color: black;
  }

  .alert-info {
    color: $color-teal-dark;
    background-color: $color-blue-light;
    border-color: $color-blue-light;
  }

  p {
    margin-bottom: 0;
  }

</style>
