<template>
  <div>
    <base-submit-info-modal ref="privilegeModal"
                            :confirm-function="handleSubmitClick"
                            cancel-button-variant="warning"
                            confirm-button-title="Submit privilege"
                            modal-title="Manage privileges">

      <b-tabs v-model="selectedTabIndex">
        <privilege-agreement-tab v-for="privilegeId in userPrivilegesIds"
                                 :key="privilegeId"
                                 :privilege-id="privilegeId"
                                 :selected-privilege-id="selectedPrivilegeId"
                                 :submit-trigger="submitTrigger"/>
      </b-tabs>
    </base-submit-info-modal>
  </div>
</template>

<script>
  import BaseSubmitInfoModal from '@/components/baseComponents/baseModals/BaseSubmitInfoModal';
  import PrivilegeAgreementTab from '@/components/userProfile/PrivilegeAgreementTab';

  export default {
    name: 'PrivilegeModal',
    components: {
      BaseSubmitInfoModal,
      PrivilegeAgreementTab,
    },
    data() {
      return {
        userPrivilegesIds: [],
        submitTrigger: false,
        selectedTabIndex: 0,
      };
    },
    computed: {
      selectedPrivilegeId() {
        return this.userPrivilegesIds[this.selectedTabIndex];
      },
    },
    created() {
      this.userPrivilegesIds = this.$store.getters['login/getLoggedUserAttribute']('privilegesIds');
    },
    methods: {
      async handleSubmitClick() {
        this.submitTrigger = !this.submitTrigger;
      },
      openModal() {
        this.$refs.privilegeModal.show();
      },
    },
  };
</script>

<style scoped>

</style>
