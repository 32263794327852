<template>
  <b-modal :ref="modalName"
           :title="modalTitle"
           bg-variant="light"
           centered
           content-class="confirm-modal-content"
           dialog-class="confirm-modal-dialog"
           header-text-variant="white"
           hide-footer
           lazy
           v-bind="$attrs"
           @hidden="hide">

    <slot/>

    <div :class="['btn-container', {'btn-container__approve': cancelButtonHidden}]">

      <b-btn v-if="!cancelButtonHidden"
             :variant="cancelButtonVariant"
             class="mt-1"
             @click="hide">
        Cancel
      </b-btn>

      <b-btn :variant="confirmButtonVariant"
             class="mt-1"
             @click="confirmFunction"
             v-text="confirmButtonTitle"/>

    </div>

  </b-modal>
</template>

<script>
  import { baseSubmitModalsMixin } from '@/mixins/baseSubmitModals';

  export default {
    name: 'BaseSubmitModal',
    mixins: [baseSubmitModalsMixin],
    props: {
      cancelButtonHidden: {
        type: Boolean,
        default: false,
      },
      cancelButtonVariant: {
        type: String,
        default: 'info',
      },
      confirmButtonVariant: {
        type: String,
        required: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  $sm-width: 575px;

  .btn-container {
    display: flex;
    justify-content: space-between;
  }

  .btn-container__approve {
    justify-content: right;
  }

  .btn-warning {
    color: white;
  }

  ::v-deep .confirm-modal-content {
    @media (max-width: $sm-width) {
      width: 600px !important;
      top: 12rem !important;
    }
  }

  ::v-deep .confirm-modal-dialog {
    @media (max-width: $sm-width) {
      align-items: unset !important;
      justify-content: center !important;
      min-height: unset !important;
    }
  }

  ::v-deep .modal-backdrop {
    width: 100% !important;
    height: calc(100vh - calc(100vh - 100%));
  }
</style>
