import Vue from 'vue';
import { calendar as urlLayer } from '@/api/url_layer';

export const calendar = {
  namespaced: true,
  state: {
    calendarConfig: { cryoem: {} },
    calendarReservations: { cryoem: [] },
  },
  mutations: {
    setCalendarConfig(state, { config, environment }) {
      state.calendarConfig[environment] = config;
    },
    setCalendarReservations(state, { reservations, environment }) {
      state.calendarReservations[environment] = reservations;
    },
  },
  actions: {
    // eslint-disable-next-line no-empty-pattern
    async _submitDocumentReservations({}, { objectId, reservations }) {
      const { data: resp } = await urlLayer.submitDocumentReservations(objectId, reservations);
      return resp;
    },
    async submitDocumentReservationsWithNotify({ dispatch }, { objectId, reservations }) {
      const resp = await dispatch('_submitDocumentReservations', { objectId, reservations });
      let notification = {};
      if (resp.msg) {
        notification = { title: resp.msg, type: 'success' };
      } else {
        notification = { title: resp.err, type: 'error', duration: 7000 };
      }
      dispatch('notifyHandler', notification);
    },
    // eslint-disable-next-line no-empty-pattern
    notifyHandler({}, notification) {
      Vue.notify(notification);
    },
    async loadCalendarConfig({ commit }, environment) {
      const response = await urlLayer.loadCalendarConfig(environment);
      commit('setCalendarConfig', { config: response.data.config, environment });
      return Promise.resolve(response);
    },
    async loadCalendarReservations({ commit }, environment) {
      const response = await urlLayer.loadCalendarReservations(environment);
      commit('setCalendarReservations', { reservations: response.data.reservations, environment });
      return Promise.resolve(response);
    },
    // eslint-disable-next-line no-empty-pattern
    async _submitCalendarConfigDates({}, { environment, configName, dates }) {
      const query = {
        dates,
        name: configName,
        check_reservation_conflict: true,
      };
      const { data: resp } = await urlLayer.submitCalendarConfigDates(environment, query);
      return resp;
    },
    async submitCalendarConfigDatesWithNotify({ dispatch }, { environment, configName, dates }) {
      const resp = await dispatch('_submitCalendarConfigDates', { environment, configName, dates });
      let notification = {};
      if (resp.msg) {
        notification = { title: resp.msg, type: 'success' };
      } else {
        notification = { title: resp.err, type: 'error', duration: 7000 };
      }
      dispatch('notifyHandler', notification);
    },
  },
};
