import { HelpersProperties } from '@/helpers/classes/helpersProperties';

export const getHelpersProperties = async (path) => {
  const fields = new HelpersProperties();
  await fields.initialize(path);
  return fields;
};

export const hasProperty = (obj, key) => Object.prototype.hasOwnProperty.call(obj, key);

export function capitalizeText(text) {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export function adaptListOfNamesToMultiselectOptions(listOfNames) {
  return listOfNames.map((el) => ({ name: el }));
}

export function adaptListOfNamesToRadioOptions(listOfNames) {
  return listOfNames.map((el) => ({
    value: el,
    text: el,
    buttonVariant: 'outline-info',
  }));
}

export function includesAny(listToCheck, elementsToCheck) {
  return elementsToCheck.some((element) => listToCheck?.includes(element));
}
