import Vue from 'vue';
import Vuex from 'vuex';
import { calls } from './calls';
import { config } from './config';
import { login } from './login';
import { reviewers } from './reviewers';
import { calendar } from './calendar';
import { usersMePrivileges } from './usersMe/usersMePrivileges';
import { proposalForm } from './proposalForm';
import { rapidAccessEvaluation } from './rapidAccessEvaluation';

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    calls,
    config,
    login,
    reviewers,
    calendar,
    usersMePrivileges,
    proposalForm,
    rapidAccessEvaluation,
  },
});
